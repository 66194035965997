import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function Title() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [titles, setTitles] = useState({});

  const location = useLocation();

  const memberId = location.pathname.split('/')[2];

  useEffect(() => {
    const fetchTitles = async () => {
      try {
        const res = await axios.get(
          `https://databasebackend.ecz.co.zw/titles/${memberId}`
        );
        setTitles(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchTitles();
  }, [memberId]);

  return (
    <div className="member">
      <div className="memberTitleContainer">
        <h1 className="memberTitle">Title Details</h1>
        <Link to={'/edit-title/' + memberId}>
          <button className="memberAddButton">Edit</button>
        </Link>
      </div>
      <h3 className="box-title">Title Name</h3>
      <hr />
      <div className="memberContainer">
        <div className="memberShow">
          <div className="memberShowBottom">
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Name:</span>{' '}
              <span className="memberShowInfoTitle">{titles.title}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
