import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function EditCountry() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [member, setMember] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  const memberId = location.pathname.split('/')[2];

  const handleChange = (e) => {
    setMember((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        'https://databasebackend.ecz.co.zw/countries/' + memberId,
        member
      );
      toast('Country has been succesfully updated');
      navigate('/countries');
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchAllMembers = async () => {
      try {
        const res = await axios.get(
          `https://databasebackend.ecz.co.zw/countries/${memberId}`
        );
        setMember(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllMembers();
  }, [memberId]);

  return (
    <div className="member">
      <div className="memberTitleContainer">
        <h1 className="memberTitle">Edit Country Details</h1>
      </div>
      <div className="memberContainer">
        <div className="memberUpdate">
          <form action="">
            <div className="form-body">
              <h3 className="box-title">Country Details</h3>
              <hr />
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="control-label">Country Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={member.country_name}
                      onChange={handleChange}
                      name="country_name"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-actions">
              <button className="btn btn-success" onClick={handleClick}>
                {' '}
                Update
              </button>
              <Link to="/countries">
                <button className="btn btn-cancel"> Cancel</button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
