import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import FetchGrades from '../components/FetchGrade';
import FetchCities from '../components/FetchCities';
import FetchAllCountries from '../components/FetchCountries';
import FetchAreas from '../components/FetchAreas';
import FetchStatus from '../components/FetchStatus';
import FetchDisciplines from '../components/FetchDisciplines';
import FetchTitles from '../components/FetchTitle';
import { toast } from 'react-toastify';

export default function EditMember() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [member, setMember] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  const memberId = location.pathname.split('/')[2];

  const handleChange = (e) => {
    setMember((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        'https://databasebackend.ecz.co.zw/members/' + memberId,
        member
      );
      toast('Member has been succesfully updated');
      navigate('/members');
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchAllMembers = async () => {
      try {
        const res = await axios.get(
          `https://databasebackend.ecz.co.zw/members/${memberId}`
        );
        setMember(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllMembers();
  }, [memberId]);

  const tempDate = new Date();
  const expiryDate = 31 + '/' + 12 + '/' + tempDate.getFullYear();

  return (
    <div className="member">
      <div className="memberTitleContainer">
        <h1 className="memberTitle">Edit Member Details</h1>
        <Link to={'/member/' + memberId}>
          <button className="memberAddButton">View</button>
        </Link>
      </div>
      <div className="memberContainer">
        <div className="memberUpdate">
          <form action="">
            <div className="form-body">
              <h3 className="box-title">Personal Details</h3>
              <hr />
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="control-label">Date Joined</label>
                    <input
                      type="text"
                      className="form-control"
                      value={member.dateJoined}
                      disabled
                    />
                  </div>
                </div>
                {/*/span*/}
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="control-label">Grade</label>
                    <select
                      className="form-control"
                      value={member.category_member}
                      onChange={handleChange}
                      name="category_member"
                    >
                      <option>Select Grade</option>
                      <FetchGrades />
                    </select>
                  </div>
                </div>
                {/*/span*/}
              </div>
              {/*/row*/}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="control-label">Surname</label>
                    <input
                      type="text"
                      value={member.surname}
                      className="form-control"
                      onChange={handleChange}
                      name="surname"
                    />
                  </div>
                </div>
                {/*/span*/}
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="control-label">Title</label>
                    <select
                      className="form-control"
                      onChange={handleChange}
                      name="title"
                      value={member.title}
                    >
                      <option>Select Title</option>
                      <FetchTitles />
                    </select>
                  </div>
                </div>
                {/*/span*/}
              </div>
              {/*/row*/}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="control-label">Forename(s)</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      name="firstname"
                      value={member.firstname}
                    />
                  </div>
                </div>
                {/*/span*/}
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="control-label">National ID</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      name="national_id"
                      value={member.national_id}
                    />
                  </div>
                </div>
                {/*/span*/}
              </div>
              {/*/row*/}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="control-label">Date of Birth</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      name="date_of_birth"
                      value={member.dob}
                    />
                  </div>
                </div>
                {/*/span*/}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Gender</label> <br />
                    <select
                      className="form-control"
                      name="gender"
                      onChange={handleChange}
                      value={member.gender}
                    >
                      <option>Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                </div>
                {/*/span*/}
              </div>
              {/*/row*/}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Passport Number</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      name="passport_number"
                      value={member.passport_number}
                    />
                  </div>
                </div>
                {/*/span*/}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Citizenship</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      name="citizenship"
                      value={member.citizenship}
                    />
                  </div>
                </div>
                {/*/span*/}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      name="personal_email"
                      value={member.personal_email}
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {/*/span*/}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Residential Address</label>
                    <textarea
                      className="form-control"
                      rows={5}
                      onChange={handleChange}
                      name="home_address"
                      value={member.home_address}
                    />
                  </div>
                </div>
                {/*/span*/}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Telephone Home</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      name="telephone_home"
                      value={member.telephone_home}
                    />
                  </div>
                </div>
                {/*/span*/}
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="control-label">Discipline</label>
                    <select
                      className="form-control"
                      onChange={handleChange}
                      name="discipline"
                      value={member.discipline}
                    >
                      <option>Select Discipline</option>
                      <FetchDisciplines />
                    </select>
                  </div>
                </div>
                {/*/span*/}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Cell</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      name="cellphone"
                      value={member.cellphone}
                    />
                  </div>
                </div>
                {/*/span*/}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>ECZ Number</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      name="ecz_number"
                      value={member.ecz_number}
                    />
                  </div>
                </div>
                {/*/span*/}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>City</label>
                    <select
                      className="form-control"
                      onChange={handleChange}
                      name="city"
                      value={member.city}
                    >
                      <option>Select City</option>
                      <FetchCities />
                    </select>
                  </div>
                </div>
                {/*/span*/}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>ZIE Membership Number</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      name="zie_membership_number"
                      value={member.zie_membership_number}
                    />
                  </div>
                </div>
                {/*/span*/}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Country</label>
                    <select
                      className="form-control"
                      onChange={handleChange}
                      name="country"
                      value={member.country}
                    >
                      <option>Select Country</option>
                      <FetchAllCountries />
                    </select>
                  </div>
                </div>
                {/*/span*/}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Payment Status</label>
                    <select
                      className="form-control"
                      name="payment_status"
                      onChange={handleChange}
                      value={member.payment_status}
                    >
                      <option>Select Payment Status</option>
                      <option value="Paid">Paid</option>
                      <option value="Not Paid">Not Paid</option>
                    </select>
                  </div>
                </div>
                {/*/span*/}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Area</label>
                    <select
                      className="form-control"
                      onChange={handleChange}
                      name="area"
                      value={member.area}
                    >
                      <option>Select Province</option>
                      <FetchAreas />
                    </select>
                  </div>
                </div>
                {/*/span*/}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Membership Expiry Date</label>
                    <input
                      type="text"
                      disabled
                      className="form-control"
                      value={expiryDate}
                    />
                  </div>
                </div>
                {/*/span*/}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Membership Status</label>
                    <select
                      className="form-control"
                      onChange={handleChange}
                      name="membership_status"
                      value={member.membership_status}
                    >
                      <option>Select Membership Status</option>
                      <FetchStatus />
                    </select>
                  </div>
                </div>
                {/*/span*/}
              </div>
              <h3 className="box-title">Employment Details</h3>
              <hr />
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="control-label">Employer</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      name="company_name"
                      value={member.company_name}
                    />
                  </div>
                </div>
                {/*/span*/}
              </div>
              {/*/row*/}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="control-label">Position</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      name="company_position"
                      value={member.company_position}
                    />
                  </div>
                </div>
                {/*/span*/}
              </div>
              {/*/row*/}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="control-label">Division</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      name="company_division"
                      value={member.company_division}
                    />
                  </div>
                </div>
                {/*/span*/}
              </div>
              {/*/row*/}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Experience (years)</label>
                    <input
                      type="number"
                      className="form-control"
                      onChange={handleChange}
                      name="experience"
                      value={member.experience}
                    />
                  </div>
                </div>
                {/*/span*/}
              </div>

              <h3 className="box-title">Qualification Details</h3>
              <hr />
              <div className="form-body">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="control-label">Qualification</label>
                      <input
                        type="text"
                        name="qualification1"
                        value={member.qualification1}
                        className="form-control"
                        onChange={handleChange}
                      />
                      <br />
                      <input
                        type="text"
                        name="qualification2"
                        value={member.qualification2}
                        className="form-control"
                        onChange={handleChange}
                      />
                      <br />
                      <input
                        type="text"
                        name="qualification3"
                        value={member.qualification3}
                        className="form-control"
                        onChange={handleChange}
                      />
                      <br />
                      <input
                        type="text"
                        name="qualification4"
                        value={member.qualification4}
                        className="form-control"
                        onChange={handleChange}
                      />
                      <br />
                      <input
                        type="text"
                        name="qualification5"
                        value={member.qualification5}
                        className="form-control"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {/*/span*/}
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Institution / College</label>
                      <input
                        type="text"
                        name="institution1"
                        value={member.institution1}
                        className="form-control"
                        onChange={handleChange}
                      />
                      <br />
                      <input
                        type="text"
                        name="institution2"
                        value={member.institution2}
                        className="form-control"
                        onChange={handleChange}
                      />
                      <br />
                      <input
                        type="text"
                        name="institution3"
                        value={member.institution3}
                        className="form-control"
                        onChange={handleChange}
                      />
                      <br />
                      <input
                        type="text"
                        name="institution4"
                        value={member.institution4}
                        className="form-control"
                        onChange={handleChange}
                      />
                      <br />
                      <input
                        type="text"
                        name="institution5"
                        value={member.institution5}
                        className="form-control"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {/*/span*/}
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>From</label>
                      <input
                        type="text"
                        name="year_study1"
                        value={member.year_study1}
                        className="form-control"
                        onChange={handleChange}
                      />
                      <br />
                      <input
                        type="text"
                        name="year_study2"
                        value={member.year_study2}
                        className="form-control"
                        onChange={handleChange}
                      />
                      <br />
                      <input
                        type="text"
                        name="year_study3"
                        value={member.year_study3}
                        className="form-control"
                        onChange={handleChange}
                      />
                      <br />
                      <input
                        type="text"
                        name="year_study4"
                        value={member.year_study4}
                        className="form-control"
                        onChange={handleChange}
                      />
                      <br />
                      <input
                        type="text"
                        name="year_study5"
                        value={member.year_study5}
                        className="form-control"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {/*/span*/}
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Year of Completion</label>
                      <input
                        type="text"
                        name="year_completion1"
                        value={member.year_completion1}
                        className="form-control"
                        onChange={handleChange}
                      />
                      <br />
                      <input
                        type="text"
                        name="year_completion2"
                        value={member.year_completion2}
                        className="form-control"
                        onChange={handleChange}
                      />
                      <br />
                      <input
                        type="text"
                        name="year_completion3"
                        value={member.year_completion3}
                        className="form-control"
                        onChange={handleChange}
                      />
                      <br />
                      <input
                        type="text"
                        name="year_completion4"
                        value={member.year_completion4}
                        className="form-control"
                        onChange={handleChange}
                      />
                      <br />
                      <input
                        type="text"
                        name="year_completion5"
                        value={member.year_completion5}
                        className="form-control"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {/*/span*/}
                </div>
                {/*/row*/}
              </div>
            </div>
            <div className="form-actions">
              <button className="btn btn-success" onClick={handleClick}>
                {' '}
                Update
              </button>
              <Link to="/members">
                <button className="btn btn-cancel"> Cancel</button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
