import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function CountActiveConsultancyFirms() {
  const [activeConsultancyFirms, setActiveConsultancyFirms] = useState([]);

  //Count total number of active consultancy firms
  useEffect(() => {
    const fetchActiveConsultancyFirms = async () => {
      try {
        const res = await axios.get(
          'https://databasebackend.ecz.co.zw/consultancyfirms/activemembers'
        );
        setActiveConsultancyFirms(res.data[0].activeConsultancyFirms);
      } catch (err) {
        console.log(err);
      }
    };
    fetchActiveConsultancyFirms();
  }, []);

  return <>{activeConsultancyFirms}</>;
}
