import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function NewStatus() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [member, setMember] = useState({
    status: '',
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setMember((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://databasebackend.ecz.co.zw/status', member);
      toast('Membership Status has been succesfully added');
      navigate('/status');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="newMemberPage">
      <div className="memberTitleContainer">
        <h1 className="newMemberTitle">New Status</h1>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="panel panel-info">
            <div className="panel-wrapper collapse in" aria-expanded="true">
              <div className="panel-body">
                <form onSubmit={handleClick}>
                  <div className="form-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">
                            Name of Status
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            name="status"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-actions">
                    <button className="btn btn-success">Add Status</button>
                    <Link to="/status">
                      <button className="btn btn-cancel"> Cancel</button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> //return end
  );
}
