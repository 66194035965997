import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function CountPaidConsultancyFirms() {
  const [paidConsultancyFirms, setPaidConsultancyFirms] = useState([]);

  //Count total number of paid up members
  useEffect(() => {
    const fetchPaidConsultancyFirms = async () => {
      try {
        const res = await axios.get(
          'https://databasebackend.ecz.co.zw/consultancyfirms/paidmembers'
        );
        setPaidConsultancyFirms(res.data[0].paidConsultancyFirms);
      } catch (err) {
        console.log(err);
      }
    };
    fetchPaidConsultancyFirms();
  }, []);

  return <>{paidConsultancyFirms}</>;
}
