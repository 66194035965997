import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function FetchTitles() {
  const [titles, setTitles] = useState([]);

  useEffect(() => {
    const fetchTitles = async () => {
      try {
        const res = await axios.get('https://databasebackend.ecz.co.zw/titles');
        setTitles(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchTitles();
  }, []);

  return (
    <>
      {titles.map((title) => (
        <option key={title.id} value={title.title}>
          {title.title && title.title}
        </option>
      ))}
    </>
  );
}
