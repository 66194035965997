import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function CountActiveMembers() {
  const [activeMembers, setActiveMembers] = useState([]);

  //Count total number of active members
  useEffect(() => {
    const fetchActiveMembers = async () => {
      try {
        const res = await axios.get(
          'https://databasebackend.ecz.co.zw/members/activemembers'
        );
        setActiveMembers(res.data[0].activeMembers);
      } catch (err) {
        console.log(err);
      }
    };
    fetchActiveMembers();
  }, []);

  return <>{activeMembers}</>;
}
