import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { DeleteOutline } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Oval } from 'react-loader-spinner';

export default function MemberList() {
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    const fetchAllMembers = async () => {
      try {
        const res = await axios.get(
          'https://databasebackend.ecz.co.zw/members'
        );
        setMembers(res.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllMembers();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete('https://databasebackend.ecz.co.zw/members/' + id);
      toast('Member has been successfully deleted');
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    { field: 'category_member', headerName: 'Category Member', width: 140 },
    { field: 'ecz_number', headerName: 'ECZ No', width: 90 },
    { field: 'zie_membership_number', headerName: 'ZIE No', width: 90 },
    { field: 'dateJoined', headerName: 'Date Joined', width: 100 },
    { field: 'membership_status', headerName: 'Status', width: 90 },
    { field: 'title', headerName: 'Title', width: 90 },
    { field: 'surname', headerName: 'Surname', width: 130 },
    { field: 'firstname', headerName: 'First Name', width: 120 },
    { field: 'discipline', headerName: 'Discipline', width: 170 },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={'/member/' + params.row.id}>
              <button className="memberListView">View</button>
            </Link>
            <Link to={'/edit/' + params.row.id}>
              <button className="memberListEdit">Edit</button>
            </Link>
            <DeleteOutline
              className="memberListDelete"
              onClick={() => handleDelete(params.row.id)}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      {loading ? (
        <Oval
          height={50}
          width={50}
          color="#0078bc"
          wrapperStyle={{}}
          wrapperClass="loader"
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#999999"
          strokeWidth={3}
          strokeWidthSecondary={3}
        />
      ) : (
        <DataGrid
          disableSelectionOnClick
          className="members-list"
          rows={members}
          columns={columns}
          checkboxSelection
          slots={{ toolbar: GridToolbar }}
          disableDensitySelector
          slotProps={{
            toolbar: {
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
            },
          }}
        />
      )}
    </>
  );
}
