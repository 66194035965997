import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function CountPaidEngineeringFirms() {
  const [paidEngineeringFirms, setPaidEngineeringFirms] = useState([]);

  //Count total number of paid up members
  useEffect(() => {
    const fetchPaidEngineeringFirms = async () => {
      try {
        const res = await axios.get(
          'https://databasebackend.ecz.co.zw/engineeringfirms/paidmembers'
        );
        setPaidEngineeringFirms(res.data[0].paidEngineeringFirms);
      } catch (err) {
        console.log(err);
      }
    };
    fetchPaidEngineeringFirms();
  }, []);

  return <>{paidEngineeringFirms}</>;
}
