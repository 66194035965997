import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import FetchNoProfessionals from '../components/FetchNoProfessionals';
import FetchAllCountries from '../components/FetchCountries';
import FetchCities from '../components/FetchCities';
import FetchAreas from '../components/FetchAreas';
import FetchStatus from '../components/FetchStatus';
import { toast } from 'react-toastify';

export default function NewConsultancyFirm() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [member, setMember] = useState({
    type_of_corporate: '',
    ecz_no: '',
    membership_no: '',
    company_name: '',
    division: '',
    physical_address: '',
    password: '',
    postal_address: '',
    company_telephone: '',
    company_cell: '',
    company_fax: '',
    company_email: '',
    country: '',
    city: '',
    area: '',
    contact_person: '',
    contact_cell: '',
    contact_telephone: '',
    contact_email: '',
    notes: '',
    expiry_date: '',
    date_joined: '',
    membership_status: '',
    payment_status: '',
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setMember((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        'https://databasebackend.ecz.co.zw/engineeringfirms',
        member
      );
      toast('New Consultancy Firm has been succesfully added');
      navigate('/consultancy-firms');
    } catch (err) {
      console.log(err);
    }
  };

  const tempDate = new Date();
  const currDate =
    tempDate.getDate() +
    '/' +
    (tempDate.getMonth() + 1) +
    '/' +
    tempDate.getFullYear();
  const expiryDate = 31 + '/' + 12 + '/' + tempDate.getFullYear();

  const [divisions, setDivisions] = useState([]);

  useEffect(() => {
    const fetchDivisions = async () => {
      try {
        const res = await axios.get(
          'https://databasebackend.ecz.co.zw/divisions'
        );
        setDivisions(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchDivisions();
  }, []);

  const [checkedDivisionValues, setDivisionValues] = useState([]);

  function handleCheckedChange(event) {
    const { value, checked } = event.target;

    if (checked) {
      setDivisionValues((pre) => [...pre, value]);
    } else {
      setDivisionValues((pre) => {
        return [...pre.filter((division) => division !== value)];
      });
    }
  }

  const dbCheckValues = checkedDivisionValues.join(', ');

  return (
    <div className="newMemberPage">
      <div className="memberTitleContainer">
        <h1 className="newMemberTitle">New Consultancy Firm</h1>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="panel panel-info">
            <div className="panel-wrapper collapse in" aria-expanded="true">
              <div className="panel-body">
                <form onSubmit={handleClick}>
                  <div className="form-body">
                    <h3 className="box-title">Company Details</h3>
                    <hr />
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">Date Joined</label>
                          <input
                            type="text"
                            disabled
                            className="form-control"
                            placeholder={currDate}
                          />
                        </div>
                        <div className="form-group">
                          <label className="control-label">
                            No. Engineering Professionals
                            <span className="required">*</span>
                          </label>
                          <select
                            className="form-control"
                            onChange={handleChange}
                            name="type_of_corporate"
                            required
                          >
                            <option>
                              Select No. Engineering Professionals
                            </option>
                            <FetchNoProfessionals />
                          </select>
                        </div>
                        <div className="form-group">
                          <label className="control-label">
                            Company Name <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            name="company_name"
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="control-label">Fax</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            name="company_fax"
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            Email <span className="required">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            onChange={handleChange}
                            name="company_email"
                            required
                          />
                        </div>
                      </div>
                      {/*/span*/}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">Division</label>
                          {divisions.map((division) => (
                            <div key={division.division} className="checkbox">
                              <input
                                id={division.division}
                                type="checkbox"
                                value={division.division}
                                onChange={handleCheckedChange}
                                name="divisionList"
                              />
                              <label htmlFor={division.division}>
                                {division.division && division.division}
                              </label>
                            </div>
                          ))}
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control hide"
                            onChange={handleChange}
                            name="divStrs"
                            value={dbCheckValues}
                          />
                        </div>
                      </div>
                      {/*/span*/}
                    </div>
                    {/*/row*/}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label for="email">
                            Telephone <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="company_telephone"
                            className="form-control"
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label>Cell</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            name="company_cell"
                          />
                        </div>
                      </div>
                      {/*/span*/}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">
                            ECZ No <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            name="ecz_no"
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="control-label">
                            ZACE No <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            name="membership_no"
                            required
                          />
                        </div>
                      </div>
                      {/*/span*/}
                    </div>
                    {/*/row*/}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Country <span className="required">*</span>
                          </label>
                          <select
                            className="form-control"
                            onChange={handleChange}
                            name="country"
                            required
                          >
                            <option>Select Country</option>
                            <FetchAllCountries />
                          </select>
                        </div>
                        <div className="form-group">
                          <label>
                            City <span className="required">*</span>
                          </label>
                          <select
                            className="form-control"
                            onChange={handleChange}
                            name="city"
                            required
                          >
                            <option>Select City</option>
                            <FetchCities />
                          </select>
                        </div>
                      </div>
                      {/*/span*/}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Physical Address <span className="required">*</span>
                          </label>
                          <textarea
                            className="form-control"
                            rows={5}
                            onChange={handleChange}
                            name="physical_address"
                            required
                          />
                        </div>
                      </div>
                      {/*/span*/}
                    </div>
                    {/*/row*/}

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Area <span className="required">*</span>
                          </label>
                          <select
                            className="form-control"
                            onChange={handleChange}
                            name="area"
                            required
                          >
                            <option>Select Province</option>
                            <FetchAreas />
                          </select>
                        </div>
                      </div>
                      {/*/span*/}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Payment Status</label>
                          <select
                            className="form-control"
                            name="payment_status"
                            onChange={handleChange}
                          >
                            <option>Select Payment Status</option>
                            <option value="Paid">Paid</option>
                            <option value="Not Paid">Not Paid</option>
                          </select>
                        </div>
                      </div>
                      {/*/span*/}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Membership Expiry Date</label>
                          <input
                            type="text"
                            disabled
                            className="form-control"
                            value={expiryDate}
                          />
                        </div>
                      </div>
                      {/*/span*/}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Membership Status{' '}
                            <span className="required">*</span>
                          </label>
                          <select
                            className="form-control"
                            onChange={handleChange}
                            name="membership_status"
                            required
                          >
                            <option>Select Membership Status</option>
                            <FetchStatus />
                          </select>
                        </div>
                      </div>
                      {/*/span*/}
                    </div>
                    <h3 className="box-title">Contact Details</h3>
                    <hr />
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">
                            Contact Person <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            name="contact_person"
                            required
                          />
                        </div>
                      </div>
                      {/*/span*/}
                    </div>
                    {/*/row*/}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">
                            Telephone <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            name="contact_telephone"
                            required
                          />
                        </div>
                      </div>
                      {/*/span*/}
                    </div>
                    {/*/row*/}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label for="email">
                            Email <span className="required">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            onChange={handleChange}
                            name="contact_email"
                            required
                          />
                        </div>
                      </div>
                      {/*/span*/}
                    </div>
                    {/*/row*/}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Cell #</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            name="contact_cell"
                          />
                        </div>
                      </div>
                      {/*/span*/}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            What role does engineering play in the activities of
                            your organisation?
                          </label>
                          <textarea
                            className="form-control"
                            rows={5}
                            onChange={handleChange}
                            name="notes"
                          />
                        </div>
                      </div>
                      {/*/span*/}
                    </div>
                  </div>
                  <div className="form-actions">
                    <button className="btn btn-success">Add Firm</button>
                    <Link to="/consultancy-firms">
                      <button className="btn btn-cancel"> Cancel</button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> //return end
  );
}
