import React, { useEffect, useState } from 'react';
import Chart from '../components/Chart';
import MemberList from '../components/MemberList';
import CountActiveMembers from '../components/CountActiveMembers';
import CountAllMembers from '../components/CountAllMembers';
import CountPaidMembers from '../components/CountPaidMembers';
import axios from 'axios';

const Home = () => {
  useEffect(() => {
    //scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [countAllMembersJan, setCountAllMembersJan] = useState([]);
  const [countAllMembersFeb, setCountAllMembersFeb] = useState([]);
  const [countAllMembersMar, setCountAllMembersMar] = useState([]);
  const [countAllMembersApr, setCountAllMembersApr] = useState([]);
  const [countAllMembersMay, setCountAllMembersMay] = useState([]);
  const [countAllMembersJun, setCountAllMembersJun] = useState([]);
  const [countAllMembersJul, setCountAllMembersJul] = useState([]);
  const [countAllMembersAug, setCountAllMembersAug] = useState([]);
  const [countAllMembersSep, setCountAllMembersSep] = useState([]);
  const [countAllMembersOct, setCountAllMembersOct] = useState([]);
  const [countAllMembersNov, setCountAllMembersNov] = useState([]);
  const [countAllMembersDec, setCountAllMembersDec] = useState([]);

  //Count total number of members
  useEffect(() => {
    const fetchAllMembers = async () => {
      try {
        const res = await axios.get(
          'https://databasebackend.ecz.co.zw/members/countalljan'
        );
        setCountAllMembersJan(res.data[0].membersCountJan);

        const resFeb = await axios.get(
          'https://databasebackend.ecz.co.zw/members/countallfeb'
        );
        setCountAllMembersFeb(resFeb.data[0].membersCountFeb);

        const resMar = await axios.get(
          'https://databasebackend.ecz.co.zw/members/countallmar'
        );
        setCountAllMembersMar(resMar.data[0].membersCountMar);

        const resApr = await axios.get(
          'https://databasebackend.ecz.co.zw/members/countallapr'
        );
        setCountAllMembersApr(resApr.data[0].membersCountApr);

        const resMay = await axios.get(
          'https://databasebackend.ecz.co.zw/members/countallmay'
        );
        setCountAllMembersMay(resMay.data[0].membersCountMay);

        const resJun = await axios.get(
          'https://databasebackend.ecz.co.zw/members/countalljun'
        );
        setCountAllMembersJun(resJun.data[0].membersCountJun);

        const resJul = await axios.get(
          'https://databasebackend.ecz.co.zw/members/countalljul'
        );
        setCountAllMembersJul(resJul.data[0].membersCountJul);

        const resAug = await axios.get(
          'https://databasebackend.ecz.co.zw/members/countallaug'
        );
        setCountAllMembersAug(resAug.data[0].membersCountAug);

        const resSep = await axios.get(
          'https://databasebackend.ecz.co.zw/members/countallsep'
        );
        setCountAllMembersSep(resSep.data[0].membersCountSep);

        const resOct = await axios.get(
          'https://databasebackend.ecz.co.zw/members/countalloct'
        );
        setCountAllMembersOct(resOct.data[0].membersCountOct);

        const resNov = await axios.get(
          'https://databasebackend.ecz.co.zw/members/countallnov'
        );
        setCountAllMembersNov(resNov.data[0].membersCountNov);

        const resDec = await axios.get(
          'https://databasebackend.ecz.co.zw/members/countalldec'
        );
        setCountAllMembersDec(resDec.data[0].membersCountDec);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllMembers();
  }, []);

  const newMembersData = [
    {
      name: 'Jan',
      'New Members': countAllMembersJan,
      'Active Members': 200,
      'Paid Up Members': 300,
    },
    {
      name: 'Feb',
      'New Members': countAllMembersFeb,
      'Active Members': 1398,
      'Paid Up Members': 2210,
    },
    {
      name: 'Mar',
      'New Members': countAllMembersMar,
      'Active Members': 9800,
      'Paid Up Members': 2290,
    },
    {
      name: 'Apr',
      'New Members': countAllMembersApr,
      'Active Members': 3908,
      'Paid Up Members': 2000,
    },
    {
      name: 'May',
      'New Members': countAllMembersMay,
      'Active Members': 4800,
      'Paid Up Members': 2181,
    },
    {
      name: 'Jun',
      'New Members': countAllMembersJun,
      'Active Members': 3800,
      'Paid Up Members': 2500,
    },
    {
      name: 'Jul',
      'New Members': countAllMembersJul,
      'Active Members': 4300,
      'Paid Up Members': 2100,
    },
    {
      name: 'Aug',
      'New Members': countAllMembersAug,
      'Active Members': 2400,
      'Paid Up Members': 3000,
    },
    {
      name: 'Sep',
      'New Members': countAllMembersSep,
      'Active Members': 1398,
      'Paid Up Members': 2210,
    },
    {
      name: 'Oct',
      'New Members': countAllMembersOct,
      'Active Members': 9800,
      'Paid Up Members': 2290,
    },
    {
      name: 'Nov',
      'New Members': countAllMembersNov,
      'Active Members': 3908,
      'Paid Up Members': 2000,
    },
    {
      name: 'Dec',
      'New Members': countAllMembersDec,
      'Active Members': 4800,
      'Paid Up Members': 2181,
    },
  ];

  const tempDate = new Date();
  const currDate = tempDate.getFullYear();

  return (
    <>
      {/* ===== Page-Content ===== */}
      <div className="page-wrapper">
        <div className="row m-0">
          <div className="col-md-4 col-sm-6 info-box">
            <div className="media">
              <div className="media-left">
                <span className="icoleaf bg-primary text-white">
                  <i className="mdi mdi-account-multiple" />
                </span>
              </div>
              <div className="media-body">
                <h3 className="info-count text-blue">
                  <CountAllMembers />
                </h3>
                <p className="info-text font-12">Members</p>
                <span className="hr-line" />
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 info-box">
            <div className="media">
              <div className="media-left">
                <span className="icoleaf bg-primary text-white">
                  <i className="mdi mdi-account-check" />
                </span>
              </div>
              <div className="media-body">
                <h3 className="info-count text-blue">
                  <CountActiveMembers />
                </h3>
                <p className="info-text font-12">Active Members</p>
                <span className="hr-line" />
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 info-box">
            <div className="media">
              <div className="media-left">
                <span className="icoleaf bg-primary text-white">
                  <i className="mdi mdi-coin" />
                </span>
              </div>
              <div className="media-body">
                <h3 className="info-count text-blue">
                  <CountPaidMembers />{' '}
                </h3>
                <p className="info-text font-12">Paid Up Members</p>
                <span className="hr-line" />
              </div>
            </div>
          </div>
        </div>
        {/* ===== Page-Container ===== */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="white-box">
                <h3 className="box-title">
                  New Members Statistics - {currDate}
                </h3>
                {/* <Chart data={userData} grid dataKey1='New Members' dataKey2='Active Members' dataKey3='Paid Up Members' /> */}
                <Chart data={newMembersData} grid dataKey1="New Members" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="white-box">
                <h3 className="box-title m-b-0">Members List</h3>
                <MemberList />
              </div>
            </div>
          </div>
        </div>
        {/* ===== Page-Container-End ===== */}
      </div>
      {/* ===== Page-Content-End ===== */}
    </>
  );
};

export default Home;
