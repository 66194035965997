import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { DeleteOutline } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Oval } from 'react-loader-spinner';

export default function EngineeringFirmsList() {
  const [loading, setLoading] = useState(true);
  const [engineeringfirms, setEngineeringFirms] = useState([]);

  useEffect(() => {
    const fetchAllMembers = async () => {
      try {
        const res = await axios.get(
          'https://databasebackend.ecz.co.zw/engineeringfirms'
        );
        setEngineeringFirms(res.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllMembers();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        'https://databasebackend.ecz.co.zw/engineeringfirms/' + id
      );
      toast('Engineering Firm has been successfully deleted');
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    { field: 'type_of_corporate', headerName: 'Type Of Corporate', width: 260 },
    { field: 'ecz_no', headerName: 'ECZ No', width: 100 },
    { field: 'membership_no', headerName: 'ZIE No', width: 100 },
    { field: 'dateJoined', headerName: 'Date Joined', width: 160 },
    { field: 'company_name', headerName: 'Company Name', width: 170 },
    { field: 'contact_person', headerName: 'Contact Person', width: 160 },
    { field: 'company_telephone', headerName: 'Company Phone', width: 150 },
    {
      field: 'action',
      headerName: 'Action',
      width: 140,
      renderCell: (params) => {
        return (
          <>
            <Link to={'/engineering-firm/' + params.row.id}>
              <button className="memberListView">View</button>
            </Link>
            <Link to={'/edit-firm/' + params.row.id}>
              <button className="memberListEdit">Edit</button>
            </Link>
            <DeleteOutline
              className="memberListDelete"
              onClick={() => handleDelete(params.row.id)}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      {loading ? (
        <Oval
          height={50}
          width={50}
          color="#0078bc"
          wrapperStyle={{}}
          wrapperClass="loader"
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#999999"
          strokeWidth={3}
          strokeWidthSecondary={3}
        />
      ) : (
        <DataGrid
          disableSelectionOnClick
          className="members-list"
          rows={engineeringfirms}
          columns={columns}
          checkboxSelection
          slots={{ toolbar: GridToolbar }}
          disableDensitySelector
          slotProps={{
            toolbar: {
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
            },
          }}
        />
      )}
    </>
  );
}
