import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function FetchStatus() {
  const [status, setStatus] = useState([]);

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const res = await axios.get('https://databasebackend.ecz.co.zw/status');
        setStatus(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchStatus();
  }, []);

  return (
    <>
      {status.map((stat) => (
        <option key={stat.id} value={stat.status}>
          {stat.status && stat.status}
        </option>
      ))}
    </>
  );
}
