import { LineChart, Line, XAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default function Chart({data, dataKey1, dataKey2, dataKey3, grid}) {

  return (
    <>
        <ResponsiveContainer width="100%" aspect={4 / 1}>
            <LineChart data={data} margin={{top: 0,right: 15, left: 15, bottom: 15}}>
                <XAxis dataKey="name" stroke='#222' />
                <Line type='monotone' dataKey={dataKey1} stroke='#0f0' />
                {/* <Line type='monotone' dataKey={dataKey2} stroke='#f00' />
                <Line type='monotone' dataKey={dataKey3} stroke='#333' /> */}
                <Tooltip />
                {grid && <CartesianGrid stroke='#e0dfdf' strokeDasharray='5 5' />}
                <Legend />
            </LineChart>
        </ResponsiveContainer>
    </>
  )
}
