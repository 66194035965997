import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function FetchNoProfessionals() {
  const [noProfessionals, setNoProfessionals] = useState([]);

  useEffect(() => {
    const fetchNoProfessionals = async () => {
      try {
        const res = await axios.get(
          'https://databasebackend.ecz.co.zw/number-professionals'
        );
        setNoProfessionals(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchNoProfessionals();
  }, []);

  return (
    <>
      {noProfessionals.map((professional) => (
        <option key={professional.id} value={professional.professionals}>
          {professional.professionals && professional.professionals}
        </option>
      ))}
    </>
  );
}
