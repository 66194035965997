import React, { useEffect } from 'react';
import MemberListReport from '../components/MemberListReport';
import CountActiveMembers from '../components/CountActiveMembers';
import CountAllMembers from '../components/CountAllMembers';
import CountArrearsMembers from '../components/CountArrearsMembers';
import CountPaidMembers from '../components/CountPaidMembers';

const Reports = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      {/* ===== Page-Content ===== */}
      <div className="page-wrapper">
        <div className="container-fluid">
          <h1 class="newMemberTitle">Members</h1>
          <div className="row colorbox-group-widget">
            <div className="col-md-3 col-sm-6 info-color-box">
              <div className="white-box">
                <div className="media bg-primary">
                  <div className="media-body">
                    <h3 className="info-count">
                      <CountAllMembers />{' '}
                      <span className="pull-right">
                        <i className="mdi mdi-account-multiple" />
                      </span>
                    </h3>
                    <p className="info-text font-12"> Members</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 info-color-box">
              <div className="white-box">
                <div className="media bg-success">
                  <div className="media-body">
                    <h3 className="info-count">
                      {' '}
                      <CountActiveMembers />{' '}
                      <span className="pull-right">
                        <i className="mdi mdi-account-check" />
                      </span>
                    </h3>
                    <p className="info-text font-12">Active Members</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 info-color-box">
              <div className="white-box">
                <div className="media bg-danger">
                  <div className="media-body">
                    <h3 className="info-count">
                      <CountArrearsMembers />{' '}
                      <span className="pull-right">
                        <i className="mdi mdi-account-off" />
                      </span>
                    </h3>
                    <p className="info-text font-12">Members In Arrears</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 info-color-box">
              <div className="white-box">
                <div className="media bg-warning">
                  <div className="media-body">
                    <h3 className="info-count">
                      <CountPaidMembers />{' '}
                      <span className="pull-right">
                        <i className="mdi mdi-coin" />
                      </span>
                    </h3>
                    <p className="info-text font-12">Paid Up Members</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===== Page-Container ===== */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="white-box">
                <h3 className="box-title m-b-0">Members</h3>
                <MemberListReport />
              </div>
            </div>
          </div>
        </div>
        {/* ===== Page-Container-End ===== */}
      </div>
      {/* ===== Page-Content-End ===== */}
    </>
  );
};

export default Reports;
