import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function CountAllMembers() {
  const [countAllMembers, setCountAllMembers] = useState([]);

  //Count total number of members
  useEffect(() => {
    const fetchAllMembers = async () => {
      try {
        const res = await axios.get(
          'https://databasebackend.ecz.co.zw/members/countall'
        );
        setCountAllMembers(res.data[0].membersCount);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllMembers();
  }, []);

  return <>{countAllMembers}</>;
}
