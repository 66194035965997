import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function FetchCities() {
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const res = await axios.get('https://databasebackend.ecz.co.zw/cities');
        setCities(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCities();
  }, []);

  return (
    <>
      {cities.map((city) => (
        <option key={city.id} value={city.city}>
          {city.city && city.city}
        </option>
      ))}
    </>
  );
}
