import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import { Oval } from 'react-loader-spinner';

export default function MemberListReport() {
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    const fetchAllMembers = async () => {
      try {
        const res = await axios.get(
          'https://databasebackend.ecz.co.zw/members'
        );
        setMembers(res.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllMembers();
  }, []);

  const columns = [
    { field: 'category_member', headerName: 'Category Member', width: 190 },
    { field: 'ecz_number', headerName: 'ECZ No', width: 200 },
    { field: 'zie_membership_number', headerName: 'ZIE No', width: 160 },
    { field: 'dateJoined', headerName: 'Date Joined', width: 150 },
    { field: 'title', headerName: 'Title', width: 90 },
    { field: 'surname', headerName: 'Surname', width: 200 },
    { field: 'firstname', headerName: 'First Name', width: 200 },
    { field: 'gender', headerName: 'Gender', width: 200 },
    { field: 'national_id', headerName: 'National ID', width: 200 },
    { field: 'dob', headerName: 'Date of Birth', width: 200 },
    { field: 'age', headerName: 'Age', width: 200 },
    { field: 'cellphone', headerName: 'Cell', width: 200 },
    { field: 'telephone_home', headerName: 'Telephone Home', width: 200 },
    {
      field: 'personal_email',
      headerName: 'Email',
      width: 200,
      cellClassName: 'toLower',
    },
    { field: 'home_address', headerName: 'Residential Address', width: 200 },
    { field: 'expiryDate', headerName: 'Membership Expiry Date', width: 230 },
    { field: 'membership_status', headerName: 'Membership Status', width: 200 },
    { field: 'city', headerName: 'City', width: 200 },
    { field: 'country', headerName: 'Country', width: 200 },
    { field: 'area', headerName: 'Area', width: 200 },
    { field: 'discipline', headerName: 'Discipline', width: 200 },
    { field: 'citizenship', headerName: 'Citizenship', width: 200 },
    { field: 'qualification1', headerName: 'Qualification', width: 200 },
    { field: 'institution1', headerName: 'Institution / College', width: 200 },
    { field: 'year_study1', headerName: 'From', width: 200 },
    { field: 'year_completion1', headerName: 'Year of Completion', width: 200 },
    { field: 'qualification2', headerName: 'Qualification', width: 200 },
    { field: 'institution2', headerName: 'Institution / College', width: 200 },
    { field: 'year_study2', headerName: 'From', width: 200 },
    { field: 'year_completion2', headerName: 'Year of Completion', width: 200 },
    { field: 'qualification3', headerName: 'Qualification', width: 200 },
    { field: 'institution3', headerName: 'Institution / College', width: 200 },
    { field: 'year_study3', headerName: 'From', width: 200 },
    { field: 'year_completion3', headerName: 'Year of Completion', width: 200 },
    { field: 'qualification4', headerName: 'Qualification', width: 200 },
    { field: 'institution4', headerName: 'Institution / College', width: 200 },
    { field: 'year_study4', headerName: 'From', width: 200 },
    { field: 'year_completion4', headerName: 'Year of Completion', width: 200 },
    { field: 'qualification5', headerName: 'Qualification', width: 200 },
    { field: 'institution5', headerName: 'Institution / College', width: 200 },
    { field: 'year_study5', headerName: 'From', width: 200 },
    { field: 'year_completion5', headerName: 'Year of Completion', width: 200 },
    { field: 'company_name', headerName: 'Employer', width: 200 },
    { field: 'company_position', headerName: 'Position', width: 200 },
    { field: 'company_division', headerName: 'Division', width: 200 },
    { field: 'experience', headerName: 'Experience', width: 200 },
    { field: 'payment_status', headerName: 'Payment Status', width: 200 },
  ];

  return (
    <>
      {loading ? (
        <Oval
          height={50}
          width={50}
          color="#0078bc"
          wrapperStyle={{}}
          wrapperClass="loader"
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#999999"
          strokeWidth={3}
          strokeWidthSecondary={3}
        />
      ) : (
        <DataGrid
          disableSelectionOnClick
          className="members-list"
          rows={members}
          columns={columns}
          checkboxSelection
          slots={{ toolbar: GridToolbar }}
          disableDensitySelector
        />
      )}
    </>
  );
}
