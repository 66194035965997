import React, { useEffect, useState } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { DeleteOutline } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Oval } from 'react-loader-spinner';

export default function AreasList() {
  const [loading, setLoading] = useState(true);
  const [areas, setAreas] = useState([]);

  useEffect(() => {
    const fetchAreas = async () => {
      try {
        const res = await axios.get('https://databasebackend.ecz.co.zw/areas');
        setAreas(res.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAreas();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete('https://databasebackend.ecz.co.zw/areas/' + id);
      toast('Area has been deleted successfully');
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    { field: 'area', headerName: 'Area', width: 250 },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={'/area/' + params.row.id}>
              <button className="memberListView">View</button>
            </Link>
            <Link to={'/edit-area/' + params.row.id}>
              <button className="memberListEdit">Edit</button>
            </Link>
            <DeleteOutline
              className="memberListDelete"
              onClick={() => handleDelete(params.row.id)}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      {loading ? (
        <Oval
          height={50}
          width={50}
          color="#0078bc"
          wrapperStyle={{}}
          wrapperClass="loader"
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#999999"
          strokeWidth={3}
          strokeWidthSecondary={3}
        />
      ) : (
        <DataGrid
          disableSelectionOnClick
          className="members-list"
          rows={areas}
          columns={columns}
          pageSizeOptions={5}
          checkboxSelection
        />
      )}
    </>
  );
}
