import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import { Oval } from 'react-loader-spinner';

export default function ConsultancyFirmsListReport() {
  const [loading, setLoading] = useState(true);
  const [engineeringfirms, setEngineeringFirms] = useState([]);

  useEffect(() => {
    const fetchAllMembers = async () => {
      try {
        const res = await axios.get(
          'https://databasebackend.ecz.co.zw/consultancyfirms'
        );
        setEngineeringFirms(res.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllMembers();
  }, []);

  const columns = [
    { field: 'type_of_corporate', headerName: 'Type Of Corporate', width: 200 },
    { field: 'ecz_no', headerName: 'ECZ No', width: 170 },
    { field: 'membership_no', headerName: 'ZACE No', width: 170 },
    { field: 'dateJoined', headerName: 'Date Joined', width: 160 },
    { field: 'company_name', headerName: 'Company Name', width: 170 },
    { field: 'division', headerName: 'Division', width: 170 },
    { field: 'physical_address', headerName: 'Physical Address', width: 170 },
    { field: 'company_telephone', headerName: 'Company Telephone', width: 170 },
    { field: 'company_cell', headerName: 'Company Cell', width: 170 },
    { field: 'company_fax', headerName: 'Company Fax', width: 170 },
    {
      field: 'company_email',
      headerName: 'Company Email',
      width: 170,
      cellClassName: 'toLower',
    },
    { field: 'area', headerName: 'Area', width: 170 },
    { field: 'city', headerName: 'City', width: 170 },
    { field: 'country', headerName: 'Country', width: 170 },
    { field: 'contact_person', headerName: 'Contact Person', width: 160 },
    { field: 'contact_cell', headerName: 'Contact Cell', width: 180 },
    { field: 'company_telephone', headerName: 'Contact Telephone', width: 180 },
    {
      field: 'contact_email',
      headerName: 'Contact Email',
      width: 180,
      cellClassName: 'toLower',
    },
    { field: 'notes', headerName: 'Notes', width: 250 },
    { field: 'expiryDate', headerName: 'Membership Expiry Date', width: 250 },
    { field: 'membership_status', headerName: 'Membership Status', width: 200 },
    { field: 'payment_status', headerName: 'Payment Status', width: 200 },
  ];

  return (
    <>
      {loading ? (
        <Oval
          height={50}
          width={50}
          color="#0078bc"
          wrapperStyle={{}}
          wrapperClass="loader"
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#999999"
          strokeWidth={3}
          strokeWidthSecondary={3}
        />
      ) : (
        <DataGrid
          disableSelectionOnClick
          className="members-list"
          rows={engineeringfirms}
          columns={columns}
          checkboxSelection
          slots={{ toolbar: GridToolbar }}
          disableDensitySelector
        />
      )}
    </>
  );
}
