/* eslint-disable no-sparse-arrays */
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import Register from './pages/Register';
import Login from './pages/Login';
import Home from './pages/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Member from './pages/Member';
import NewMember from './pages/NewMember';
import Members from './pages/Members';
import EditMember from './pages/EditMember';
import EngineeringFirms from './pages/EngineeringFirms';
import NewEngineeringFirm from './pages/NewEngineeringFirm';
import EngineeringFirm from './pages/EngineeringFirm';
import EditFirm from './pages/EditFirm';
import ConsultancyFirms from './pages/ConsultancyFirms';
import NewConsultancyFirm from './pages/NewConsultancyFirm';
import Reports from './pages/Reports';
import EngineeringFirmsReports from './pages/EngineeringFirmsReports';
import ConsultancyFirmsReports from './pages/ConsultancyFirmsReports';
import Email from './pages/Email';
import Profile from './pages/Profile';
import Countries from './pages/Countries';
import ManageCities from './pages/ManageCities';
import City from './pages/City';
import EditCity from './pages/EditCity';
import NewCity from './pages/NewCity';
import EditConsultancyFirm from './pages/EditConsultancyFirm';
import ConsultancyFirm from './pages/ConsultancyFirm';
import Country from './pages/Country';
import EditCountry from './pages/EditCountry';
import NewCountry from './pages/NewCountry';
import ManageAreas from './pages/ManageAreas';
import Area from './pages/Area';
import EditArea from './pages/EditArea';
import NewArea from './pages/NewArea';
import ManageStatus from './pages/ManageStatus';
import Status from './pages/Status';
import EditStatus from './pages/EditStatus';
import ManageGrades from './pages/ManageGrades';
import NewGrade from './pages/NewGrade';
import Grade from './pages/Grade';
import EditGrade from './pages/EditGrade';
import ManageTitles from './pages/ManageTitles';
import Title from './pages/Title';
import EditTitle from './pages/EditTitle';
import NewTitle from './pages/NewTitle';
import ManageDisciplines from './pages/ManageDisciplines';
import Discipline from './pages/Discipline';
import EditDiscipline from './pages/EditDiscipline';
import NewDiscipline from './pages/NewDiscipline';
import NewStatus from './pages/NewStatus';
import ManageNoProfessionals from './pages/ManageNoProfessionals';
import NoEngineeringProfessional from './pages/NoEngineeringProfessional';
import EditNoProfessional from './pages/EditNoProfessional';
import NewNoProfessional from './pages/NewNoProfessional';
import ManageDivisions from './pages/ManageDivisions';
import Division from './pages/Division';
import EditDivision from './pages/EditDivision';
import NewDivision from './pages/NewDivision';
import { useContext, useEffect } from 'react';
import { AuthContext } from './context/authContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Layout = () => {
  const { currentUser, logout } = useContext(AuthContext);

  //function to check for inactivity and logout
  const checkForInactivity = () => {
    //Get expire time from local storage
    const expireTime = localStorage.getItem('expireTime');

    //If expire time is earlier than now, then logout
    if (expireTime < Date.now()) {
      logout();
    }
  };

  //function to update expire time
  const updateExpireTime = () => {
    //set expire time to 30 minutes from now
    const expireTime = Date.now() + 1800000;

    //set expire time in local storage
    localStorage.setItem('expireTime', expireTime);
  };

  //use effect to set interval to check for inactivity
  useEffect(() => {
    //check for inactivity every 5 seconds
    const interval = setInterval(() => {
      checkForInactivity();
    }, 900000);

    //clear interval on unmount
    return () => clearInterval(interval);
  }, []);

  //update expire time on any user activity
  useEffect(() => {
    //set initial expire time
    updateExpireTime();

    //set event listeners
    window.addEventListener('click', updateExpireTime);
    window.addEventListener('keypress', updateExpireTime);
    window.addEventListener('scroll', updateExpireTime);
    window.addEventListener('mousemove', updateExpireTime);

    //clean up
    return () => {
      window.removeEventListener('click', updateExpireTime);
      window.removeEventListener('keypress', updateExpireTime);
      window.removeEventListener('scroll', updateExpireTime);
      window.removeEventListener('mousemove', updateExpireTime);
    };
  }, []);

  return (
    <>
      {currentUser ? <Navbar /> : <Login />}
      {currentUser ? <Outlet /> : <Login />}
      {currentUser ? <Footer /> : <Login />}
    </>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
      {
        path: '/home',
        element: <Home />,
      },
      {
        path: '/member/:memberId',
        element: <Member />,
      },
      {
        path: '/country/:countryId',
        element: <Country />,
      },
      {
        path: '/city/:cityId',
        element: <City />,
      },
      {
        path: '/area/:areaId',
        element: <Area />,
      },
      {
        path: '/status/:statusId',
        element: <Status />,
      },
      {
        path: '/grade/:gradeId',
        element: <Grade />,
      },
      {
        path: '/title/:titleId',
        element: <Title />,
      },
      {
        path: '/discipline/:disciplineId',
        element: <Discipline />,
      },
      {
        path: '/professional/:professionalId',
        element: <NoEngineeringProfessional />,
      },
      {
        path: '/division/:divisionId',
        element: <Division />,
      },
      {
        path: '/engineering-firm/:memberId',
        element: <EngineeringFirm />,
      },
      {
        path: '/consultancy-firm/:memberId',
        element: <ConsultancyFirm />,
      },
      {
        path: '/edit/:memberId',
        element: <EditMember />,
      },
      {
        path: '/profile/:memberId',
        element: <Profile />,
      },
      {
        path: '/edit-firm/:memberId',
        element: <EditFirm />,
      },
      {
        path: '/edit-consultancy-firm/:memberId',
        element: <EditConsultancyFirm />,
      },
      {
        path: '/edit-city/:cityId',
        element: <EditCity />,
      },
      {
        path: '/edit-country/:countryId',
        element: <EditCountry />,
      },
      {
        path: '/edit-area/:areaId',
        element: <EditArea />,
      },
      {
        path: '/edit-status/:statusId',
        element: <EditStatus />,
      },
      {
        path: '/edit-grade/:gradeId',
        element: <EditGrade />,
      },
      {
        path: '/edit-title/:titleId',
        element: <EditTitle />,
      },
      {
        path: '/edit-discipline/:disciplineId',
        element: <EditDiscipline />,
      },
      {
        path: '/edit-professional/:professionalId',
        element: <EditNoProfessional />,
      },
      {
        path: '/edit-division/:divisionId',
        element: <EditDivision />,
      },
      {
        path: '/new-member',
        element: <NewMember />,
      },
      {
        path: '/new-firm',
        element: <NewEngineeringFirm />,
      },
      {
        path: '/new-country',
        element: <NewCountry />,
      },
      {
        path: '/new-city',
        element: <NewCity />,
      },
      {
        path: '/new-area',
        element: <NewArea />,
      },
      {
        path: '/new-consultancy-firm',
        element: <NewConsultancyFirm />,
      },
      {
        path: '/new-grade',
        element: <NewGrade />,
      },
      {
        path: '/new-title',
        element: <NewTitle />,
      },
      {
        path: '/new-discipline',
        element: <NewDiscipline />,
      },
      {
        path: '/new-status',
        element: <NewStatus />,
      },
      {
        path: '/new-division',
        element: <NewDivision />,
      },
      {
        path: '/new-noprofessional',
        element: <NewNoProfessional />,
      },
      {
        path: '/members',
        element: <Members />,
      },
      {
        path: '/engineering-firms',
        element: <EngineeringFirms />,
      },
      {
        path: '/consultancy-firms',
        element: <ConsultancyFirms />,
      },
      {
        path: '/reports',
        element: <Reports />,
      },
      {
        path: '/engineering-firms-reports',
        element: <EngineeringFirmsReports />,
      },
      {
        path: '/consultancy-firms-reports',
        element: <ConsultancyFirmsReports />,
      },
      {
        path: '/email',
        element: <Email />,
      },
      {
        path: '/countries',
        element: <Countries />,
      },
      {
        path: '/cities',
        element: <ManageCities />,
      },
      {
        path: '/status',
        element: <ManageStatus />,
      },
      {
        path: '/areas',
        element: <ManageAreas />,
      },
      {
        path: '/grades',
        element: <ManageGrades />,
      },
      {
        path: '/titles',
        element: <ManageTitles />,
      },
      {
        path: '/disciplines',
        element: <ManageDisciplines />,
      },
      {
        path: '/professionals',
        element: <ManageNoProfessionals />,
      },
      {
        path: '/divisions',
        element: <ManageDivisions />,
      },
    ],
  },
  {
    path: '/register',
    element: <Register />,
  },
  ,
  {
    path: '/login',
    element: <Login />,
  },
]);

function App() {
  return (
    <div className="app">
      <RouterProvider router={router} />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
