import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import EngineeringFirmsList from '../components/EngineeringFirmsList'

export default function EngineeringFirms() {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
    <div className='eczmembers'>
      <div className="memberTitleContainer">
        <h1 className="newMemberTitle">Engineering Firms</h1>
        <Link to="/new-firm"><button className="memberAddButton">Create New</button></Link>
      </div>
        <EngineeringFirmsList />
    </div>
  )
}
