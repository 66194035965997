import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function FetchAreas() {
  const [areas, setAreas] = useState([]);

  useEffect(() => {
    const fetchAreas = async () => {
      try {
        const res = await axios.get('https://databasebackend.ecz.co.zw/areas');
        setAreas(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAreas();
  }, []);

  return (
    <>
      {areas.map((area) => (
        <option key={area.id} value={area.area}>
          {area.area && area.area}
        </option>
      ))}
    </>
  );
}
