import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function CountAllConsultancyFirms() {
  const [countAllConsultancyFirms, setCountAllConsultancyFirms] = useState([]);

  //Count total number of consultancy firms
  useEffect(() => {
    const fetchAllMembers = async () => {
      try {
        const res = await axios.get(
          'https://databasebackend.ecz.co.zw/consultancyfirms/countall'
        );
        setCountAllConsultancyFirms(res.data[0].consultancyFirmsCount);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllMembers();
  }, []);

  return <>{countAllConsultancyFirms}</>;
}
