import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import GradesList from '../components/GradesList'

export default function ManageGrades() {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  
  return (
    <div className='eczmembers'>
      <div className="memberTitleContainer">
        <h1 className="newMemberTitle">Grades</h1>
        <Link to="/new-grade"><button className="memberAddButton">Add New</button></Link>
      </div>
        <GradesList />
    </div>
  )
}
