import React from 'react'

const tempDate = new Date();
const date = tempDate.getFullYear();
const currDate = date;

const Footer = () => {
  return (
    <>
        <footer className="footer t-a-c">
            © Copyright {currDate}. Engineering Council of Zimbabwe. All Rights Reserved.
        </footer>

    </>
  )
}

export default Footer