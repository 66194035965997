import { useContext } from 'react';
import Logo from '../images/logo.png';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/authContext';

const Navbar = () => {
  const { currentUser, logout } = useContext(AuthContext);

  return (
    <>
      {/* ===== Top-Navigation ===== */}
      <nav className="navbar navbar-default navbar-static-top m-b-0">
        <div className="navbar-header">
          {/* <a className="navbar-toggle font-20 hidden-sm hidden-md hidden-lg" href="javascript:void(0)" data-toggle="collapse" data-target=".navbar-collapse">
                <i className="fa fa-bars" />
                </a> */}
          <div className="top-left-part">
            <Link className="logo" to="/home">
              <b>
                <img src={Logo} alt="home" />
              </b>
            </Link>
          </div>
          <ul className="nav navbar-top-links navbar-right pull-right">
            <li className="dropdown">
              <Link
                className="dropdown-toggle waves-effect waves-light font-20"
                to="/email"
              >
                <i className="icon-envelope" />
              </Link>
            </li>
            <li className="dropdown settings-links">
              <Link to={'/profile/' + currentUser.id}>
                <span className="top-username">{currentUser?.username}</span>
              </Link>
              {currentUser ? (
                <span onClick={logout} className="top-logout">
                  Logout
                </span>
              ) : (
                <Link className="top-username" to="/login">
                  Login
                </Link>
              )}
            </li>
          </ul>
        </div>
      </nav>

      {/* ===== Top-Navigation-End ===== */}
      {/* ===== Left-Sidebar ===== */}
      <aside className="sidebar">
        <div className="scroll-sidebar">
          <nav className="sidebar-nav">
            <ul id="side-menu">
              <li>
                <Link className="waves-effect" to="/home" aria-expanded="false">
                  <i className="icon-screen-desktop fa-fw" />
                  <span className="hide-menu"> Dashboard </span>
                </Link>
              </li>
              <li>
                <Link className="waves-effect" to="/members">
                  <i className="icon-people fa-fw" />
                  <span className="hide-menu"> ECZ Membership </span>
                </Link>
              </li>
              <li>
                <Link className="waves-effect" to="/engineering-firms">
                  <i className="icon-briefcase fa-fw" />
                  <span className="hide-menu"> Engineering Firms </span>
                </Link>
              </li>
              <li>
                <Link className="waves-effect" to="/consultancy-firms">
                  <i className="icon-briefcase fa-fw" />
                  <span className="hide-menu"> Consultancy Firms </span>
                </Link>
              </li>
              <li>
                <a
                  className="waves-effect"
                  href="javascript:void(0);"
                  aria-expanded="false"
                >
                  <i className="icon-notebook fa-fw" />
                  <span className="hide-menu"> Reports </span>
                </a>
                <ul aria-expanded="false" className="collapse">
                  <li>
                    <Link to="/reports">Members</Link>
                  </li>
                  <li>
                    <Link to="/engineering-firms-reports">
                      Engineering Firms
                    </Link>
                  </li>
                  <li>
                    <Link to="/consultancy-firms-reports">
                      Consultancy Firms
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  className="waves-effect"
                  href="javascript:void(0);"
                  aria-expanded="false"
                >
                  <i className="icon-settings fa-fw" />
                  <span className="hide-menu"> Manage </span>
                </a>
                <ul aria-expanded="false" className="collapse">
                  <li>
                    <Link to="/disciplines">Disciplines</Link>
                  </li>
                  <li>
                    <Link to="/professionals">
                      No. Engineering Professionals
                    </Link>
                  </li>
                  <li>
                    <Link to="/grades">Grades</Link>
                  </li>
                  <li>
                    <Link to="/divisions">Divisions</Link>
                  </li>
                  <li>
                    <Link to="/status">Membership Status</Link>
                  </li>
                  <li>
                    <Link to="/countries">Countries</Link>
                  </li>
                  <li>
                    <Link to="/cities">Cities</Link>
                  </li>
                  <li>
                    <Link to="/areas">Areas</Link>
                  </li>
                  <li>
                    <Link to="/titles">Titles</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
      {/* ===== Left-Sidebar-End ===== */}
    </>
  );
};

export default Navbar;
