import React, { useEffect } from 'react';
import ConsultancyFirmsListReport from '../components/ConsultancyFirmsListReport';
import CountAllConsultancyFirms from '../components/CountAllConsultancyFirms';
import CountActiveConsultancyFirms from '../components/CountActiveConsultancyFirms';
import CountArrearsConsultancyFirms from '../components/CountArrearsConsultancyFirms';
import CountPaidConsultancyFirms from '../components/CountPaidConsultancyFirms';

const ConsultancyFirmsReports = () => {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
    <>
      {/* ===== Page-Content ===== */}
        <div className="page-wrapper">        
          <div className="container-fluid">
          <h1 class="newMemberTitle">Consultancy Firms</h1>
            <div className="row colorbox-group-widget">
              <div className="col-md-3 col-sm-6 info-color-box">
                <div className="white-box">
                  <div className="media bg-primary">
                    <div className="media-body">
                      <h3 className="info-count"><CountAllConsultancyFirms /> <span className="pull-right"><i className="mdi mdi-checkbox-marked-circle-outline" /></span></h3>
                      <p className="info-text font-12"> Consultancy Firms</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 info-color-box">
                <div className="white-box">
                  <div className="media bg-success">
                    <div className="media-body">
                      <h3 className="info-count"><CountActiveConsultancyFirms /> <span className="pull-right"><i className="mdi mdi-comment-text-outline" /></span></h3>
                      <p className="info-text font-12">Active Consultancy Firms</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 info-color-box">
                <div className="white-box">
                  <div className="media bg-danger">
                    <div className="media-body">
                      <h3 className="info-count"><CountArrearsConsultancyFirms /> <span className="pull-right"><i className="mdi mdi-coin" /></span></h3>
                      <p className="info-text font-12">Consultancy Firms In Arrears</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 info-color-box">
              <div className="white-box">
                <div className="media bg-warning">
                  <div className="media-body">
                    <h3 className="info-count"><CountPaidConsultancyFirms /> <span className="pull-right"><i className="mdi mdi-coin" /></span></h3>
                    <p className="info-text font-12">Paid Up Consultancy Firms</p>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>

          {/* ===== Page-Container ===== */}
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="white-box">
                  <h3 className="box-title m-b-0">Consultancy Firms</h3>
                  <ConsultancyFirmsListReport />
                </div>
              </div>
            </div>


          </div>
          {/* ===== Page-Container-End ===== */}
        </div>
      {/* ===== Page-Content-End ===== */}
    </>
  )
}

export default ConsultancyFirmsReports