import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const Email = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [emailsMembers, setEmailsMembers] = useState([]);
  const [emailsEngineering, setEmailsEngineering] = useState([]);
  const [emailsConsultancy, setEmailsConsultancy] = useState([]);

  useEffect(() => {
    const fetchEmailsMembers = async () => {
      try {
        const res = await axios.get(
          'https://databasebackend.ecz.co.zw/emails-members'
        );
        setEmailsMembers(
          res.data.map((object) => `${object.personal_email}`).join(',')
        );
        const res1 = await axios.get(
          'https://databasebackend.ecz.co.zw/emails-engineering'
        );
        setEmailsEngineering(
          res1.data.map((object) => `${object.contact_email}`).join(',')
        );
        const res2 = await axios.get(
          'https://databasebackend.ecz.co.zw/emails-consultancy'
        );
        setEmailsConsultancy(
          res2.data.map((object) => `${object.contact_email}`).join(',')
        );
      } catch (err) {
        console.log(err);
      }
    };
    fetchEmailsMembers();
  }, []);

  const [mailContent, setMailContent] = useState({
    recipient_email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    setMailContent((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  function sendMail() {
    if (mailContent) {
      try {
        axios.post('https://databasebackend.ecz.co.zw/send-email', mailContent);
        toast('Email has been successfully sent');
      } catch (err) {
        console.log(err);
        toast('Message has not been sent');
      }
    }
  }

  return (
    <>
      {/* ===== Page-Content ===== */}
      <div className="page-wrapper">
        {/* ===== Page-Container ===== */}
        <div className="container-fluid">
          <h1 className="newMemberTitle">Send Email</h1>
          <div className="row">
            <div className="col-md-12">
              <div className="white-box">
                {/* row */}
                <div className="row">
                  <form onSubmit={() => sendMail()}>
                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12 mail_listing">
                      <h3 className="box-title">Compose New Email</h3>
                      <div className="form-group">
                        {/* <input
                          type="text"
                          required=""
                          className="form-control"
                          name="recipient_email"
                          id="recipient_email"
                          onChange={handleChange}
                          placeholder="To:"
                        /> */}
                        <select
                          className="form-control"
                          required
                          name="recipient_email"
                          id="recipient_email"
                          onChange={handleChange}
                        >
                          <option>To: Select Recipients</option>
                          <option value={emailsMembers}>
                            Send to All Members
                          </option>
                          <option value={emailsEngineering}>
                            Send to All Engineering Firms
                          </option>
                          <option value={emailsConsultancy}>
                            Send to All Consultancy Firms
                          </option>
                        </select>
                      </div>
                      <div className="form-group">
                        <input
                          className="form-control"
                          required
                          placeholder="Subject:"
                          name="subject"
                          id="subject"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <textarea
                          className="textarea_editor form-control"
                          rows="15"
                          placeholder="Enter message ..."
                          name="message"
                          id="message"
                          onChange={handleChange}
                          required
                        ></textarea>
                      </div>
                      <hr />
                      <button
                        // onClick={() => sendMail()}
                        className="btn btn-primary"
                      >
                        <i className="fa fa-envelope-o"></i> Send
                      </button>
                    </div>
                  </form>
                </div>
                {/* /.row */}
              </div>
            </div>
          </div>
        </div>
        {/* ===== Page-Container-End ===== */}
      </div>
      {/* ===== Page-Content-End ===== */}
    </>
  );
};

export default Email;
