import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function FetchGrades() {
  const [grades, setGrades] = useState([]);

  useEffect(() => {
    const fetchGrades = async () => {
      try {
        const res = await axios.get('https://databasebackend.ecz.co.zw/grades');
        setGrades(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchGrades();
  }, []);

  return (
    <>
      {grades.map((grade) => (
        <option key={grade.id} value={grade.grade}>
          {grade.grade && grade.grade}
        </option>
      ))}
    </>
  );
}
