import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function Area() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [area, setArea] = useState({});

  const location = useLocation();

  const memberId = location.pathname.split('/')[2];

  useEffect(() => {
    const fetchAreas = async () => {
      try {
        const res = await axios.get(
          `https://databasebackend.ecz.co.zw/areas/${memberId}`
        );
        setArea(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAreas();
  }, [memberId]);

  return (
    <div className="member">
      <div className="memberTitleContainer">
        <h1 className="memberTitle">Area Details</h1>
        <Link to={'/edit-area/' + memberId}>
          <button className="memberAddButton">Edit</button>
        </Link>
      </div>
      <h3 className="box-title">Area Name</h3>
      <hr />
      <div className="memberContainer">
        <div className="memberShow">
          <div className="memberShowBottom">
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Name:</span>{' '}
              <span className="memberShowInfoTitle">{area.area}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
