import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function FetchAllCountries() {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchAllCountries = async () => {
      try {
        const res = await axios.get(
          'https://databasebackend.ecz.co.zw/countries'
        );
        setCountries(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllCountries();
  }, []);

  return (
    <>
      {countries.map((country) => (
        <option key={country.id} value={country.country_name}>
          {country.country_name && country.country_name}
        </option>
      ))}
    </>
  );
}
