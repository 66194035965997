import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function CountPaidMembers() {
  const [paidMembers, setPaidMembers] = useState([]);

  //Count total number of paid up members
  useEffect(() => {
    const fetchPaidMembers = async () => {
      try {
        const res = await axios.get(
          'https://databasebackend.ecz.co.zw/members/paidmembers'
        );
        setPaidMembers(res.data[0].paidMembers);
      } catch (err) {
        console.log(err);
      }
    };
    fetchPaidMembers();
  }, []);

  return <>{paidMembers}</>;
}
