import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function ConsultancyFirm() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [member, setMember] = useState({});

  const location = useLocation();

  const memberId = location.pathname.split('/')[2];

  useEffect(() => {
    const fetchAllMembers = async () => {
      try {
        const res = await axios.get(
          `https://databasebackend.ecz.co.zw/engineeringfirms/${memberId}`
        );
        setMember(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllMembers();
  }, [memberId]);

  return (
    <div className="member">
      <div className="memberTitleContainer">
        <h1 className="memberTitle">Consultancy Firm Details</h1>
        <Link to={'/edit-consultancy-firm/' + memberId}>
          <button className="memberAddButton">Edit</button>
        </Link>
      </div>
      <h3 className="box-title">Company Details</h3>
      <hr />
      <div className="memberContainer">
        <div className="memberShow">
          <div className="memberShowBottom">
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Date Joined:</span>{' '}
              <span className="memberShowInfoTitle">{member.dateJoined}</span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">
                No. Engineering Professionals:
              </span>{' '}
              <span className="memberShowInfoTitle">
                {member.type_of_corporate}
              </span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Company Name:</span>
              <span className="memberShowInfoTitle">{member.company_name}</span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Fax:</span>
              <span className="memberShowInfoTitle">{member.company_fax}</span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Email:</span>
              <span className="memberShowInfoTitle email">
                {member.company_email}
              </span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Telephone:</span>
              <span className="memberShowInfoTitle">
                {member.company_telephone}
              </span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Cell:</span>
              <span className="memberShowInfoTitle">{member.company_cell}</span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Country:</span>
              <span className="memberShowInfoTitle">{member.country}</span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">City:</span>
              <span className="memberShowInfoTitle">{member.city}</span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Area:</span>
              <span className="memberShowInfoTitle">{member.area}</span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">
                Membership Expiry Date:
              </span>
              <span className="memberShowInfoTitle">{member.expiryDate}</span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Membership Status:</span>
              <span className="memberShowInfoTitle">
                {member.membership_status}
              </span>
            </div>
          </div>
        </div>
        <div className="memberShow">
          <div className="memberShowBottom">
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Division:</span>{' '}
              <span className="memberShowInfoTitle">{member.division}</span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">ECZ No:</span>{' '}
              <span className="memberShowInfoTitle">{member.ecz_no}</span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">ZACE No:</span>{' '}
              <span className="memberShowInfoTitle">
                {member.membership_no}
              </span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Physical Address:</span>{' '}
              <span className="memberShowInfoTitle">
                {member.physical_address}
              </span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Postal Address:</span>{' '}
              <span className="memberShowInfoTitle">
                {member.postal_address}
              </span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Payment Status:</span>{' '}
              <span className="memberShowInfoTitle">
                {member.payment_status}
              </span>
            </div>
          </div>
        </div>
      </div>

      <h3 className="box-title">Contact Details</h3>
      <hr />
      <div className="memberContainer">
        <div className="memberShow">
          <div className="memberShowBottom">
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Contact Person:</span>{' '}
              <span className="memberShowInfoTitle">
                {member.contact_person}
              </span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Telephone:</span>{' '}
              <span className="memberShowInfoTitle">
                {member.contact_telephone}
              </span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Email:</span>
              <span className="memberShowInfoTitle email">
                {member.contact_email}
              </span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Cell #:</span>
              <span className="memberShowInfoTitle">{member.contact_cell}</span>
            </div>
            <div className="memberShowInfo fullwidth">
              <span className="memberShowInfoLabel fullwidth">
                What role does engineering play in the activities of your
                organisation?:
              </span>{' '}
              <br /> <span className="memberShowInfoTitle">{member.notes}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
