import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/authContext';
import { toast } from 'react-toastify';
import Home from './Home';

const Login = () => {
  const [inputs, setInputs] = useState({
    username: '',
    password: '',
  });

  const [err, setError] = useState(null);

  const navigate = useNavigate();

  const { currentUser, login } = useContext(AuthContext);

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(inputs);
      toast('You have succesfully logged in');
      navigate('/home');
    } catch (err) {
      setError(err.response.data);
    }
  };

  return (
    <>
      {currentUser ? (
        <Home />
      ) : (
        <section id="wrapper" className="login-register">
          <div className="login-box">
            <div className="white-box">
              <form className="form-horizontal form-material" id="loginform">
                <h3 className="box-title m-b-20">Sign In</h3>
                <div className="form-group">
                  <div className="col-xs-12">
                    <input
                      className="form-control"
                      type="text"
                      required
                      placeholder="Username"
                      name="username"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-xs-12">
                    <input
                      className="form-control"
                      type="password"
                      required
                      placeholder="Password"
                      name="password"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group text-center m-t-20">
                  <div className="col-xs-12">
                    <button
                      className="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light"
                      onClick={handleSubmit}
                    >
                      Log In
                    </button>
                    {err && <p className="error">{err}</p>}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Login;
