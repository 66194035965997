import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function CountActiveEngineeringFirms() {
  const [activeEngineeringFirms, setActiveEngineeringFirms] = useState([]);

  //Count total number of active members
  useEffect(() => {
    const fetchActiveEngineeringFirms = async () => {
      try {
        const res = await axios.get(
          'https://databasebackend.ecz.co.zw/engineeringfirms/activemembers'
        );
        setActiveEngineeringFirms(res.data[0].activeEngineeringFirms);
      } catch (err) {
        console.log(err);
      }
    };
    fetchActiveEngineeringFirms();
  }, []);

  return <>{activeEngineeringFirms}</>;
}
