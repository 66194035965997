import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function CountAllEngineeringFirms() {
  const [countAllEngineeringFirms, setCountAllEngineeringFirms] = useState([]);

  //Count total number of engineering firms
  useEffect(() => {
    const fetchAllMembers = async () => {
      try {
        const res = await axios.get(
          'https://databasebackend.ecz.co.zw/engineeringfirms/countall'
        );
        setCountAllEngineeringFirms(res.data[0].engineeringFirmsCount);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllMembers();
  }, []);

  return <>{countAllEngineeringFirms}</>;
}
