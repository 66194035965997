import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function Member() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [member, setMember] = useState({});

  const location = useLocation();

  const memberId = location.pathname.split('/')[2];

  useEffect(() => {
    const fetchAllMembers = async () => {
      try {
        const res = await axios.get(
          `https://databasebackend.ecz.co.zw/members/${memberId}`
        );
        setMember(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllMembers();
  }, [memberId]);

  return (
    <div className="member">
      <div className="memberTitleContainer">
        <h1 className="memberTitle">Member Details</h1>
        <Link to={'/edit/' + memberId}>
          <button className="memberAddButton">Edit</button>
        </Link>
      </div>
      <h3 className="box-title">Personal Details</h3>
      <hr />
      <div className="memberContainer">
        <div className="memberShow">
          <div className="memberShowBottom">
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Date Joined:</span>
              <span className="memberShowInfoTitle">{member.dateJoined}</span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Surname:</span>
              <span className="memberShowInfoTitle">{member.surname}</span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Forename(s):</span>
              <span className="memberShowInfoTitle">{member.firstname}</span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Date of Birth:</span>
              <span className="memberShowInfoTitle">{member.dob}</span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Passport Number:</span>
              <span className="memberShowInfoTitle">
                {member.passport_number}
              </span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Email:</span>
              <span className="memberShowInfoTitle email">
                {member.personal_email}
              </span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Telephone Home:</span>
              <span className="memberShowInfoTitle">
                {member.telephone_home}
              </span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Cell:</span>
              <span className="memberShowInfoTitle">{member.cellphone}</span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">City:</span>
              <span className="memberShowInfoTitle">{member.city}</span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Country:</span>
              <span className="memberShowInfoTitle">{member.country}</span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Area:</span>
              <span className="memberShowInfoTitle">{member.area}</span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">
                Membership Expiry Date:
              </span>
              <span className="memberShowInfoTitle">{member.expiryDate}</span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Membership Status:</span>
              <span className="memberShowInfoTitle">
                {member.membership_status}
              </span>
            </div>
          </div>
        </div>
        <div className="memberShow">
          <div className="memberShowBottom">
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Grade:</span>
              <span className="memberShowInfoTitle">
                {member.category_member}
              </span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Title:</span>
              <span className="memberShowInfoTitle">{member.title}</span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">National ID:</span>
              <span className="memberShowInfoTitle">{member.national_id}</span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Gender:</span>
              <span className="memberShowInfoTitle">{member.gender}</span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Citizenship:</span>
              <span className="memberShowInfoTitle">{member.citizenship}</span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Residential Address:</span>
              <span className="memberShowInfoTitle">{member.home_address}</span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Discipline:</span>
              <span className="memberShowInfoTitle">{member.discipline}</span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">ECZ Number:</span>
              <span className="memberShowInfoTitle">{member.ecz_number}</span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">
                ZIE Membership Number:
              </span>
              <span className="memberShowInfoTitle">
                {member.zie_membership_number}
              </span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Payment Status:</span>
              <span className="memberShowInfoTitle">
                {member.payment_status}
              </span>
            </div>
          </div>
        </div>
      </div>

      <h3 className="box-title">Employment Details</h3>
      <hr />
      <div className="memberContainer">
        <div className="memberShow">
          <div className="memberShowBottom">
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Employer:</span>
              <span className="memberShowInfoTitle">{member.company_name}</span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Position:</span>
              <span className="memberShowInfoTitle">
                {member.company_position}
              </span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Division:</span>
              <span className="memberShowInfoTitle">
                {member.company_division}
              </span>
            </div>
            <div className="memberShowInfo">
              <span className="memberShowInfoLabel">Experience (years):</span>
              <span className="memberShowInfoTitle">{member.experience}</span>
            </div>
          </div>
        </div>
      </div>

      <h3 className="box-title">Qualification Details</h3>
      <hr />
      <div className="memberContainer">
        <div className="memberShow">
          <div className="memberShowBottom">
            {member.qualification1 && (
              <div className="memberShowInfo">
                <span className="memberShowInfoLabel">
                  {member.qualification1}:
                </span>
                <span className="memberShowInfoTitle">
                  {member.institution1}
                </span>
                &nbsp; ({member.year_study1} - {member.year_completion1})
              </div>
            )}
            {member.qualification2 && (
              <div className="memberShowInfo">
                <span className="memberShowInfoLabel">
                  {member.qualification2}:
                </span>
                <span className="memberShowInfoTitle">
                  {member.institution2}
                </span>
                &nbsp; ({member.year_study2} - {member.year_completion2})
              </div>
            )}
            {member.qualification3 && (
              <div className="memberShowInfo">
                <span className="memberShowInfoLabel">
                  {member.qualification3}:
                </span>
                <span className="memberShowInfoTitle">
                  {member.institution3}
                </span>
                &nbsp; ({member.year_study3} - {member.year_completion3})
              </div>
            )}
            {member.qualification4 && (
              <div className="memberShowInfo">
                <span className="memberShowInfoLabel">
                  {member.qualification4}:
                </span>
                <span className="memberShowInfoTitle">
                  {member.institution4}
                </span>
                &nbsp; ({member.year_study4} - {member.year_completion4})
              </div>
            )}
            {member.qualification5 && (
              <div className="memberShowInfo">
                <span className="memberShowInfoLabel">
                  {member.qualification5}:
                </span>
                <span className="memberShowInfoTitle">
                  {member.institution5}
                </span>
                &nbsp; ({member.year_study5} - {member.year_completion5})
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
