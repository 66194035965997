import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import FetchCities from '../components/FetchCities';
import FetchStatus from '../components/FetchStatus';
import FetchAreas from '../components/FetchAreas';
import FetchAllCountries from '../components/FetchCountries';
import FetchNoProfessionals from '../components/FetchNoProfessionals';
import { toast } from 'react-toastify';

export default function EditConsultancyFirm() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [member, setMember] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  const memberId = location.pathname.split('/')[2];

  const handleChange = (e) => {
    setMember((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        'https://databasebackend.ecz.co.zw/engineeringfirms/' + memberId,
        member
      );
      toast('Consultancy Firm has been succesfully updated');
      navigate('/consultancy-firms');
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchAllMembers = async () => {
      try {
        const res = await axios.get(
          `https://databasebackend.ecz.co.zw/engineeringfirms/${memberId}`
        );
        setMember(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllMembers();
  }, [memberId]);

  return (
    <div className="member">
      <div className="memberTitleContainer">
        <h1 className="memberTitle">Edit Consultancy Firm Details</h1>
        <Link to={'/engineering-firm/' + memberId}>
          <button className="memberAddButton">View</button>
        </Link>
      </div>
      <div className="memberContainer">
        <div className="memberUpdate">
          <form action="">
            <div className="form-body">
              <h3 className="box-title">Company Details</h3>
              <hr />
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="control-label">Date Joined</label>
                    <input
                      type="text"
                      disabled
                      className="form-control"
                      placeholder={member.dateJoined}
                    />
                  </div>
                  <div className="form-group">
                    <label className="control-label">
                      No. Engineering Professionals
                    </label>
                    <select
                      className="form-control"
                      onChange={handleChange}
                      name="type_of_corporate"
                      value={member.type_of_corporate}
                    >
                      <option>Select No. Engineering Professionals</option>
                      <FetchNoProfessionals />
                    </select>
                  </div>
                  <div className="form-group">
                    <label className="control-label">Company Name</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      name="company_name"
                      value={member.company_name}
                    />
                  </div>
                  <div className="form-group">
                    <label className="control-label">Fax</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      name="company_fax"
                      value={member.company_fax}
                    />
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      onChange={handleChange}
                      name="company_email"
                      value={member.company_email}
                    />
                  </div>
                </div>
                {/*/span*/}
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="control-label">Division</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      name="division"
                      value={member.division}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="control-label">ECZ No</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      name="ecz_no"
                      value={member.ecz_no}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="control-label">ZACE No</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      name="membership_no"
                      value={member.membership_no}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Physical Address</label>
                    <textarea
                      className="form-control"
                      rows={5}
                      onChange={handleChange}
                      name="physical_address"
                      value={member.physical_address}
                    />
                  </div>
                </div>
              </div>
              {/*/row*/}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label for="email">Telephone</label>
                    <input
                      type="text"
                      name="company_telephone"
                      className="form-control"
                      onChange={handleChange}
                      value={member.company_telephone}
                    />
                  </div>
                  <div className="form-group">
                    <label>Cell</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      name="company_cell"
                      value={member.company_cell}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Payment Status</label>
                    <select
                      className="form-control"
                      name="payment_status"
                      onChange={handleChange}
                      value={member.payment_status}
                    >
                      <option>Select Payment Status</option>
                      <option value="Paid">Paid</option>
                      <option value="Not Paid">Not Paid</option>
                    </select>
                  </div>
                </div>
              </div>
              {/*/row*/}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Country</label>
                    <select
                      className="form-control"
                      onChange={handleChange}
                      name="country"
                      value={member.country}
                    >
                      <option>Select Country</option>
                      <FetchAllCountries />
                    </select>
                  </div>
                  <div className="form-group">
                    <label>City</label>
                    <select
                      className="form-control"
                      onChange={handleChange}
                      name="city"
                      value={member.city}
                    >
                      <option>Select City</option>
                      <FetchCities />
                    </select>
                  </div>
                </div>
              </div>
              {/*/row*/}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Area</label>
                    <select
                      className="form-control"
                      onChange={handleChange}
                      name="area"
                      value={member.area}
                    >
                      <option>Select Province</option>
                      <FetchAreas />
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Membership Expiry Date</label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={member.expiryDate}
                    />
                  </div>
                </div>
                {/*/span*/}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Membership Status</label>
                    <select
                      className="form-control"
                      onChange={handleChange}
                      name="membership_status"
                      value={member.membership_status}
                    >
                      <option>Select Membership Status</option>
                      <FetchStatus />
                    </select>
                  </div>
                </div>
                {/*/span*/}
              </div>
              <h3 className="box-title">Contact Details</h3>
              <hr />
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="control-label">Contact Person</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      name="contact_person"
                      value={member.contact_person}
                    />
                  </div>
                </div>
                {/*/span*/}
              </div>
              {/*/row*/}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="control-label">Telephone</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      name="contact_telephone"
                      value={member.contact_telephone}
                    />
                  </div>
                </div>
              </div>
              {/*/row*/}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label for="email">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      onChange={handleChange}
                      name="contact_email"
                      value={member.contact_email}
                    />
                  </div>
                </div>
                {/*/span*/}
              </div>
              {/*/row*/}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Cell #</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      name="contact_cell"
                      value={member.contact_cell}
                    />
                  </div>
                </div>
                {/*/span*/}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      What role does engineering play in the activities of your
                      organisation?
                    </label>
                    <textarea
                      className="form-control"
                      rows={5}
                      onChange={handleChange}
                      name="notes"
                      value={member.notes}
                    />
                  </div>
                </div>
                {/*/span*/}
              </div>
            </div>
            <div className="form-actions">
              <button className="btn btn-success" onClick={handleClick}>
                {' '}
                Update
              </button>
              <Link to="/consultancy-firms">
                <button className="btn btn-cancel"> Cancel</button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
