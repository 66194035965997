import axios from 'axios';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Register = () => {
  const [inputs, setInputs] = useState({
    username: '',
    email: '',
    password: '',
  });

  const [err, setError] = useState(null);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        'https://databasebackend.ecz.co.zw/register',
        inputs
      );
      navigate('/login');
    } catch (err) {
      setError(err.response.data);
    }
  };

  return (
    <>
      <section id="wrapper" className="login-register">
        <div className="login-box">
          <div className="white-box">
            <form
              className="form-horizontal form-material"
              id="loginform"
              onSubmit={handleSubmit}
            >
              <h3 className="box-title m-b-20">Register</h3>
              <div className="form-group">
                <div className="col-xs-12">
                  <input
                    className="form-control"
                    type="text"
                    required
                    placeholder="Username"
                    name="username"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="col-xs-12">
                  <input
                    className="form-control"
                    type="email"
                    required
                    placeholder="Email"
                    name="email"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="col-xs-12">
                  <input
                    className="form-control"
                    type="password"
                    required
                    placeholder="Password"
                    name="password"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group text-center m-t-20">
                <div className="col-xs-12">
                  <button className="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light">
                    Register
                  </button>
                  {err && <p className="error">{err}</p>}
                </div>
              </div>

              <div className="form-group m-b-0">
                <div className="col-sm-12 text-center">
                  <p>
                    Have an account?
                    <Link to="/login" className="text-primary m-l-5">
                      <b>Login</b>
                    </Link>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;
