import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/authContext';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Profile = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [user, setUser] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  const memberId = location.pathname.split('/')[2];

  const { currentUser, logout } = useContext(AuthContext);

  const handleChange = (e) => {
    setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        'https://databasebackend.ecz.co.zw/users/' + memberId,
        user
      );
      toast('You have succesfully updated your profile');
      logout();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {/* ===== Page-Content ===== */}
      <div className="page-wrapper">
        {/* ===== Page-Container ===== */}
        <div className="container-fluid">
          <h1 class="newMemberTitle">My Profile</h1>
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <div className="white-box">
                <form className="form-horizontal form-material">
                  <div className="form-group">
                    <label className="col-md-12">
                      <strong>Username</strong>
                    </label>
                    <div className="col-md-12">
                      <input
                        type="text"
                        defaultValue={currentUser.username}
                        className="form-control form-control-line"
                        name="username"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-12">
                      <strong>Email</strong>
                    </label>
                    <div className="col-md-12">
                      <input
                        type="email"
                        defaultValue={currentUser.email}
                        className="form-control form-control-line"
                        name="email"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-12">
                      <strong>Password</strong>
                    </label>
                    <div className="col-md-12">
                      <input
                        type="password"
                        className="form-control form-control-line"
                        name="password"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="col-sm-12">
                      <button className="btn btn-success" onClick={handleClick}>
                        Update Profile
                      </button>
                      <Link to="/home">
                        <button className="btn btn-cancel"> Cancel</button>
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* ===== Page-Container-End ===== */}
      </div>
      {/* ===== Page-Content-End ===== */}
    </>
  );
};

export default Profile;
