import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function CountArrearsConsultancyFirms() {
  const [arrearsConsultancyFirms, setArrearsConsultancyFirms] = useState([]);

  //Count total number of consultancy firms in arrears
  useEffect(() => {
    const fetchArrearsConsultancyFirms = async () => {
      try {
        const res = await axios.get(
          'https://databasebackend.ecz.co.zw/consultancyfirms/arrearsmembers'
        );
        setArrearsConsultancyFirms(res.data[0].arrearsConsultancyFirms);
      } catch (err) {
        console.log(err);
      }
    };
    fetchArrearsConsultancyFirms();
  }, []);

  return <>{arrearsConsultancyFirms}</>;
}
