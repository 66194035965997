import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import FetchCountries from '../components/FetchCountries';
import FetchGrades from '../components/FetchGrade';
import FetchTitles from '../components/FetchTitle';
import FetchDisciplines from '../components/FetchDisciplines';
import FetchCities from '../components/FetchCities';
import FetchAreas from '../components/FetchAreas';
import FetchStatus from '../components/FetchStatus';
import { toast } from 'react-toastify';

export default function NewMember() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [member, setMember] = useState({
    category_member: '',
    ecz_number: '',
    zie_membership_number: '',
    title: '',
    surname: '',
    firstname: '',
    gender: '',
    national_id: '',
    passport_number: '',
    date_of_birth: '',
    cellphone: '',
    telephone_home: '',
    personal_email: '',
    home_address: '',
    membership_expiry_date: '',
    membership_status: '',
    city: '',
    country: '',
    area: '',
    discipline: '',
    citizenship: '',
    qualification1: '',
    qualification2: '',
    qualification3: '',
    qualification4: '',
    qualification5: '',
    institution1: '',
    institution2: '',
    institution3: '',
    institution4: '',
    institution5: '',
    year_study1: '',
    year_study2: '',
    year_study3: '',
    year_study4: '',
    year_study5: '',
    year_completion1: '',
    year_completion2: '',
    year_completion3: '',
    year_completion4: '',
    year_completion5: '',
    company_name: '',
    company_position: '',
    company_division: '',
    experience: '',
    payment_status: '',
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setMember((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://databasebackend.ecz.co.zw/members', member);
      toast('New Member has been succesfully added');
      navigate('/members');
    } catch (err) {
      console.log(err);
    }
  };

  const tempDate = new Date();
  const currDate =
    tempDate.getDate() +
    '/' +
    (tempDate.getMonth() + 1) +
    '/' +
    tempDate.getFullYear();
  const expiryDate = 31 + '/' + 12 + '/' + tempDate.getFullYear();

  return (
    <div className="newMemberPage">
      <div className="memberTitleContainer">
        <h1 className="newMemberTitle">New Member</h1>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="panel panel-info">
            <div className="panel-wrapper collapse in" aria-expanded="true">
              <div className="panel-body">
                <form onSubmit={handleClick}>
                  <div className="form-body">
                    <h3 className="box-title">Personal Details</h3>
                    <hr />
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">Date Joined</label>
                          <input
                            type="text"
                            disabled
                            className="form-control"
                            value={currDate}
                          />
                        </div>
                      </div>
                      {/*/span*/}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">
                            Grade <span className="required">*</span>
                          </label>
                          <select
                            className="form-control"
                            required
                            onChange={handleChange}
                            name="category_member"
                          >
                            <option>Select Grade</option>
                            <FetchGrades />
                          </select>
                          <br />
                        </div>
                      </div>
                      {/*/span*/}
                    </div>
                    {/*/row*/}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">
                            Surname <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            required
                            className="form-control"
                            onChange={handleChange}
                            name="surname"
                          />
                        </div>
                      </div>
                      {/*/span*/}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">
                            Title <span className="required">*</span>
                          </label>
                          <select
                            className="form-control"
                            onChange={handleChange}
                            name="title"
                            required
                          >
                            <option>Select Title</option>
                            <FetchTitles />
                          </select>
                        </div>
                      </div>
                      {/*/span*/}
                    </div>
                    {/*/row*/}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">
                            Forename(s) <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            name="firstname"
                            required
                          />
                        </div>
                      </div>
                      {/*/span*/}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">
                            National ID <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            name="national_id"
                            required
                          />
                        </div>
                      </div>
                      {/*/span*/}
                    </div>
                    {/*/row*/}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">
                            Date of Birth <span className="required">*</span>
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={handleChange}
                            name="date_of_birth"
                            format="yyyy-mm-dd"
                            required
                          />
                        </div>
                      </div>
                      {/*/span*/}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Gender <span className="required">*</span>
                          </label>{' '}
                          <br />
                          <select
                            className="form-control"
                            name="gender"
                            onChange={handleChange}
                            required
                          >
                            <option>Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="female">Female</option>
                          </select>
                        </div>
                      </div>
                      {/*/span*/}
                    </div>
                    {/*/row*/}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Passport Number</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            name="passport_number"
                          />
                        </div>
                      </div>
                      {/*/span*/}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Citizenship <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            name="citizenship"
                            required
                          />
                        </div>
                      </div>
                      {/*/span*/}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label for="email">
                            Email <span className="required">*</span>
                          </label>
                          <input
                            type="email"
                            name="personal_email"
                            className="form-control"
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label>Telephone Home</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            name="telephone_home"
                          />
                        </div>
                      </div>
                      {/*/span*/}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Residential Address{' '}
                            <span className="required">*</span>
                          </label>
                          <textarea
                            className="form-control"
                            rows={5}
                            onChange={handleChange}
                            name="home_address"
                            required
                          />
                        </div>
                      </div>
                      {/*/span*/}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Cell</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            name="cellphone"
                          />
                        </div>
                      </div>
                      {/*/span*/}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">
                            Discipline <span className="required">*</span>
                          </label>
                          <select
                            className="form-control"
                            onChange={handleChange}
                            name="discipline"
                            required
                          >
                            <option>Select Discipline</option>
                            <FetchDisciplines />
                          </select>
                        </div>
                      </div>
                      {/*/span*/}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            City <span className="required">*</span>
                          </label>
                          <select
                            className="form-control"
                            onChange={handleChange}
                            name="city"
                            required
                          >
                            <option>Select City</option>
                            <FetchCities />
                          </select>
                        </div>
                      </div>
                      {/*/span*/}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            ZIE Membership Number{' '}
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            name="zie_membership_number"
                            required
                          />
                        </div>
                      </div>
                      {/*/span*/}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Country <span className="required">*</span>
                          </label>
                          <select
                            className="form-control"
                            onChange={handleChange}
                            name="country"
                            required
                          >
                            <option>Select Country</option>
                            <FetchCountries />
                          </select>
                        </div>
                      </div>
                      {/*/span*/}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            ECZ Number <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            name="ecz_number"
                            required
                          />
                        </div>
                      </div>
                      {/*/span*/}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Area <span className="required">*</span>
                          </label>
                          <select
                            className="form-control"
                            onChange={handleChange}
                            name="area"
                            required
                          >
                            <option>Select Province</option>
                            <FetchAreas />
                          </select>
                        </div>
                      </div>
                      {/*/span*/}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Payment Status</label>
                          <select
                            className="form-control"
                            name="payment_status"
                            onChange={handleChange}
                          >
                            <option>Select Payment Status</option>
                            <option value="Paid">Paid</option>
                            <option value="Not Paid">Not Paid</option>
                          </select>
                        </div>
                      </div>
                      {/*/span*/}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Membership Expiry Date</label>
                          <input
                            type="text"
                            disabled
                            className="form-control"
                            value={expiryDate}
                          />
                        </div>
                      </div>
                      {/*/span*/}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Membership Status
                            <span className="required">*</span>
                          </label>
                          <select
                            className="form-control"
                            onChange={handleChange}
                            name="membership_status"
                            required
                          >
                            <option>Select Membership Status</option>
                            <FetchStatus />
                          </select>
                        </div>
                      </div>
                      {/*/span*/}
                    </div>
                    <h3 className="box-title">Employment Details</h3>
                    <hr />
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">Employer</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            name="company_name"
                          />
                        </div>
                      </div>
                      {/*/span*/}
                    </div>
                    {/*/row*/}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">Position</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            name="company_position"
                          />
                        </div>
                      </div>
                    </div>
                    {/*/row*/}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">Division</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            name="company_division"
                          />
                        </div>
                      </div>
                      {/*/span*/}
                    </div>
                    {/*/row*/}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Experience (years)</label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={handleChange}
                            name="experience"
                          />
                        </div>
                      </div>
                      {/*/span*/}
                    </div>

                    <h3 className="box-title">Qualification Details</h3>
                    <hr />
                    <div className="form-body">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="control-label">
                              Qualification
                            </label>
                            <input
                              type="text"
                              name="qualification1"
                              className="form-control"
                              onChange={handleChange}
                            />
                            <br />
                            <input
                              type="text"
                              name="qualification2"
                              className="form-control"
                              onChange={handleChange}
                            />
                            <br />
                            <input
                              type="text"
                              name="qualification3"
                              className="form-control"
                              onChange={handleChange}
                            />
                            <br />
                            <input
                              type="text"
                              name="qualification4"
                              className="form-control"
                              onChange={handleChange}
                            />
                            <br />
                            <input
                              type="text"
                              name="qualification5"
                              className="form-control"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        {/*/span*/}
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Institution / College</label>
                            <input
                              type="text"
                              name="institution1"
                              className="form-control"
                              onChange={handleChange}
                            />
                            <br />
                            <input
                              type="text"
                              name="institution2"
                              className="form-control"
                              onChange={handleChange}
                            />
                            <br />
                            <input
                              type="text"
                              name="institution3"
                              className="form-control"
                              onChange={handleChange}
                            />
                            <br />
                            <input
                              type="text"
                              name="institution4"
                              className="form-control"
                              onChange={handleChange}
                            />
                            <br />
                            <input
                              type="text"
                              name="institution5"
                              className="form-control"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        {/*/span*/}
                        <div className="col-md-2">
                          <div className="form-group">
                            <label>From</label>
                            <input
                              type="text"
                              name="year_study1"
                              className="form-control"
                              onChange={handleChange}
                            />
                            <br />
                            <input
                              type="text"
                              name="year_study2"
                              className="form-control"
                              onChange={handleChange}
                            />
                            <br />
                            <input
                              type="text"
                              name="year_study3"
                              className="form-control"
                              onChange={handleChange}
                            />
                            <br />
                            <input
                              type="text"
                              name="year_study4"
                              className="form-control"
                              onChange={handleChange}
                            />
                            <br />
                            <input
                              type="text"
                              name="year_study5"
                              className="form-control"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        {/*/span*/}
                        <div className="col-md-2">
                          <div className="form-group">
                            <label>Year of Completion</label>
                            <input
                              type="text"
                              name="year_completion1"
                              className="form-control"
                              onChange={handleChange}
                            />
                            <br />
                            <input
                              type="text"
                              name="year_completion2"
                              className="form-control"
                              onChange={handleChange}
                            />
                            <br />
                            <input
                              type="text"
                              name="year_completion3"
                              className="form-control"
                              onChange={handleChange}
                            />
                            <br />
                            <input
                              type="text"
                              name="year_completion4"
                              className="form-control"
                              onChange={handleChange}
                            />
                            <br />
                            <input
                              type="text"
                              name="year_completion5"
                              className="form-control"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        {/*/span*/}
                      </div>
                      {/*/row*/}
                    </div>
                  </div>
                  <div className="form-actions">
                    <button className="btn btn-success"> Add Member</button>
                    <Link to="/members">
                      <button className="btn btn-cancel"> Cancel</button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> //return end
  );
}
