import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function CountArrearsMembers() {
  const [arrearsMembers, setArrearsMembers] = useState([]);

  //Count total number of arrears members
  useEffect(() => {
    const fetchArrearsMembers = async () => {
      try {
        const res = await axios.get(
          'https://databasebackend.ecz.co.zw/members/arrearsmembers'
        );
        setArrearsMembers(res.data[0].arrearsMembers);
      } catch (err) {
        console.log(err);
      }
    };
    fetchArrearsMembers();
  }, []);

  return <>{arrearsMembers}</>;
}
