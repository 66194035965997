import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function FetchDisciplines() {
  const [disciplines, setDisciplines] = useState([]);

  useEffect(() => {
    const fetchDisciplines = async () => {
      try {
        const res = await axios.get(
          'https://databasebackend.ecz.co.zw/disciplines'
        );
        setDisciplines(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchDisciplines();
  }, []);

  return (
    <>
      {disciplines.map((discipline) => (
        <option key={discipline.id} value={discipline.discipline}>
          {discipline.discipline && discipline.discipline}
        </option>
      ))}
    </>
  );
}
